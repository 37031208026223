'use client';
import axios from 'axios';
import { motion } from 'framer-motion';
import { Form } from 'react-bootstrap';
import GoogleLogin from 'react-google-login';
import { ArrowRight } from 'react-feather';
import Link from 'next/link';
import classes from './LoginForm.module.css';
import RecentlyViewed from '@/components/Carousels/RecentlyViewed';
import { useState } from 'react';
import { useRouter } from 'next/navigation';
import Image from 'next/image';
import { useAuthContext } from '@/context';
import { useAlertContext } from '@/context/alert';
import FacebookLoginWrapper from './FacebookLoginWrapper';

const LoginForm = () => {
	const { auth, LOGIN_SUCCESS } = useAuthContext();
	const { SET_ALERT } = useAlertContext();

	const [loading, setLoading] = useState();

	const router = useRouter();

	const cart = global?.window
		? localStorage.cart &&
		  Array.isArray(JSON.parse(localStorage.cart)) && [
				...JSON.parse(localStorage.cart),
		  ]
		: [];

	const [formData, setFormData] = useState({
		email: '',
		password: '',
	});

	const { email, password } = formData;

	const submitHandler = async (event) => {
		event.preventDefault();
		setLoading(true);

		loginHandler({
			userEmail: null,
			name: null,
			picture: null,
			fbId: null,
			glId: null,
		});
	};

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const loginHandler = async (props) => {
		const { userEmail, name, picture, fbId, glId } = props;

		setLoading(true);

		await axios
			.post(`${process.env.NEXT_PUBLIC_SERVER_API}/api/auth`, {
				email: userEmail ? userEmail : email,
				password,
				name,
				guestUserId:
					localStorage.getItem('guestUser') &&
					localStorage.getItem('guestUser') !== 'undefined' &&
					localStorage.getItem('guestUser') !== 'null'
						? localStorage.getItem('guestUser')
						: '',
				source:
					localStorage.getItem('source') &&
					localStorage.getItem('source') !== 'undefined' &&
					localStorage.getItem('source') !== 'null'
						? localStorage.getItem('source')
						: 'NA',
				picture,
				glId,
				fbId,
				cart: cart ? cart : [],
			})
			//if succesful:
			.then((res) => {
				localStorage.removeItem('source');
				localStorage.removeItem('guestUser');

				SET_ALERT({
					msg: 'Logged in!',
					type: 'success',
				});

				const { user } = res.data;

				// Segment identify Users
				process.env.NEXT_PUBLIC_NODE_ENV === 'production' &&
					process.env.OG_DB === 'true' &&
					global?.window &&
					window.analytics.identify(user._id, {
						firstName: user.firstName,
						lastName: user.lastName ? user.lastName : '',
						email: user.email,
						phone: user.phone ? user.phone : '',
					});

				process.env.NEXT_PUBLIC_NODE_ENV === 'production' &&
					process.env.OG_DB === 'true' &&
					global?.window &&
					window.analytics.track('login', {
						method: user.OAuth ? user.OAuth : 'email',
					});

				LOGIN_SUCCESS(res.data);
				router.back();
			})
			//login fail
			.catch((error) => {
				console.log(error);

				SET_ALERT({
					msg:
						error.response?.data?.errors?.length > 0
							? error.response.data.errors[0].msg
							: 'Server error',
					type: 'danger',
				});
			});
		setLoading(false);
	};

	const responseFacebook = async (response) => {
		if (response.accessToken) {
			loginHandler({
				userEmail: response.email,
				name: response.name,
				picture: response.picture
					? response.picture.data.url
						? response.picture.data.url
						: null
					: null,
				fbId: response.id,
				glId: null,
			});
		}
	};

	const responseGoogle = async (response) => {
		if (response.tokenId) {
			loginHandler({
				userEmail: response.profileObj.email,
				name: response.profileObj.givenName
					? response.profileObj.givenName
					: response.profileObj.name,
				picture: response.profileObj.imageUrl
					? response.profileObj.imageUrl
					: null,
				glId: response.profileObj.googleId,
				fbId: null,
			});
		}
	};

	return (
		<>
			<div className={classes.main}>
				<div className={classes.leftContent}>
					<div className={classes.leftSection}>
						<Link href={'/'}>
							<Image
								fill
								src={
									'https://ik.imagekit.io/kuj9zqlrx0or/logo-black.avif?tr=w-400'
								}
								alt="kashew logo second hand"
								className={classes.logo}
							/>
						</Link>
						<>
							<h1 className={classes.text}>
								{' '}
								Buy and sell used furniture made easy.
							</h1>

							<Image
								fill
								alt="Buy and sell used furniture with Kashew"
								src="https://ik.imagekit.io/kuj9zqlrx0or/Static/landing/landingHero.png?tr=w-600"
								className={classes.armChair}
							/>
						</>
					</div>
				</div>
				<div className={classes.rightContent}>
					<div className={classes.header}>
						<div className={classes.visibleAtWidth}>
							<Link href={'/'}>
								<Image
									fill
									alt="kashew logo second hand"
									src={
										'https://ik.imagekit.io/kuj9zqlrx0or/logo-black.avif?tr=w-400'
									}
									className={classes.logo}
								/>
							</Link>
						</div>

						{!auth.isAuthenticated && (
							<>
								<div className={classes.headerText}>Sign In</div>
								<div className={`${classes.headerParagraph} p-text`}>
									Log in to start buying, selling, adding to favorites and
									sending offers
								</div>
							</>
						)}

						{auth.isAuthenticated && (
							<>
								<div className={classes.authenticated}>
									<div className={classes.headerText}>Explore Kashew</div>
									<div className={classes.headerParagraph}>
										You&apos;re logged in and ready to go!
									</div>
									<div className={classes.linkBox}>
										<Link
											href={'/'}
											className={`${classes.headerParagraph1} p-text`}
										>
											Home <ArrowRight size={15} />
										</Link>
										<Link
											href={'/collections'}
											className={`${classes.headerParagraph1} p-text`}
										>
											Buy <ArrowRight size={15} />
										</Link>
										<Link
											href={'/consign-with-us'}
											className={`${classes.headerParagraph1} p-text`}
										>
											Sell <ArrowRight size={15} />
										</Link>
										<Link
											href={'/how-it-works'}
											className={`${classes.headerParagraph1} p-text`}
										>
											How it Works <ArrowRight size={15} />
										</Link>
										<Link
											href={`/profile/${auth.user._id}`}
											className={`${classes.headerParagraph1} p-text`}
										>
											Profile <ArrowRight size={15} />
										</Link>
									</div>
								</div>

								<RecentlyViewed />
							</>
						)}
					</div>

					{!auth.isAuthenticated && (
						<>
							<motion.div
								initial={{ x: '100vh' }}
								animate={{ x: 0 }}
								transition={{
									duration: 0.2,
								}}
							>
								<div className={classes.formDiv}>
									<Form onSubmit={submitHandler}>
										<div className={classes.formDiv}>
											<GoogleLogin
												clientId="766168399417-jq3ifugiaqecrimfbkql8qkkcpmvrqcg.apps.googleusercontent.com"
												buttonText="WITH GOOGLE"
												prompt="select_account"
												onSuccess={responseGoogle}
												onFailure={responseGoogle}
												cookiePolicy={'single_host_origin'}
												className={classes.googleButton}
											/>
										</div>

										<div className={classes.formDiv}>
											<FacebookLoginWrapper
												facebookResponseCallBack={responseFacebook}
											/>
										</div>

										<div className={classes.separator}>
											<div className={classes.line}></div>
											<div className={classes.separatorText}>or</div>
											<div className={classes.line}></div>
										</div>

										<Form.Group className="mb-3" controlId="Email">
											<Form.Label>Email</Form.Label>
											<Form.Control
												type="email"
												placeholder="Enter your email"
												name="email"
												onChange={(e) => onChange(e)}
												required
											/>
										</Form.Group>

										<Form.Group className="mb-3" controlId="Password">
											<Form.Label>Password</Form.Label>
											<Form.Control
												type="password"
												name="password"
												placeholder="Password"
												onChange={(e) => onChange(e)}
												required
											/>
										</Form.Group>

										<button type="submit" className={classes.button}>
											{loading ? (
												<svg
													width="20px"
													height="20px"
													viewBox="0 0 135 135"
													xmlns="http://www.w3.org/2000/svg"
													fill="#fff"
												>
													<path d="M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z">
														<animateTransform
															attributeName="transform"
															type="rotate"
															from="0 67 67"
															to="-360 67 67"
															dur="2.5s"
															repeatCount="indefinite"
														/>
													</path>
													<path d="M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z">
														<animateTransform
															attributeName="transform"
															type="rotate"
															from="0 67 67"
															to="360 67 67"
															dur="8s"
															repeatCount="indefinite"
														/>
													</path>
												</svg>
											) : (
												`Login`
											)}
										</button>
									</Form>
								</div>
							</motion.div>
							<div className={classes.helpLinks}>
								Forgot password? <div className={classes.spaceTab} />
								<Link className={classes.Link} href={'/reset'}>
									<div className={classes.color}>Reset</div>
								</Link>
							</div>
							<div className={classes.absolute}>
								Don&apos;t have an account yet? <br />
								<Link className={classes.Link} href={'/register'}>
									<div className={classes.logIn}>Register</div>
								</Link>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default LoginForm;
